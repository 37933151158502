import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {

  const [isNavbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!isNavbarOpen);
  };
  
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className='relative bg-white pb-20 overflow-hidden'>
      <nav className='relative z-10 py-7'>
          <div className='container mx-auto px-4'>
            <div className='relative flex items-center justify-between'>
              <a className='inline-block' href='#'>
                <img
                  className='h-8'
                  src='images/logo.svg'
                  alt=''
                />
              </a>
              <div className='flex items-center justify-end'>
                <div className='hidden lg:block mr-10'>
                  <a
                    className='inline-flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-gray-800 hover:bg-gray-100 transition duration-200 rounded-full mr-3'
                    href='https://blog.gptbr.com'
                  >
                    Blog
                  </a>
                  <a
                    className='inline-flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-gray-800 hover:bg-gray-100 transition duration-200 rounded-full'
                    href='https://chat.gptbr.com'
                  >
                    ACESSE O CHAGPT
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <img
          className='absolute top-0 right-0'
          src='images/circle-double-element.svg'
          alt=''
        />
        <div className='relative pt-20'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -mx-4 mb-9'>
              <div className='w-full lg:w-1/2 px-4 mb-8 lg:mb-0'>
                <div className='relative max-w-md lg:max-w-none leading-3'>
                  <h1 className='font-heading text-4xl xs:text-5xl md:text-7xl text-black font-semibold leading-none md:leading-tight'>
                    TRAZENDO O FUTURO DA IA PARA O BRASIL
                  </h1>
                </div>
              </div>
              <div className='w-full lg:w-1/2 px-4'>
                <div className='max-w-sm lg:ml-auto'>
                  <p className='text-2xl text-gray-400 mb-8'>
                    <span className='block'>
                      Somos a GPTbr, uma organização que democratiza o acesso a
                      inteligência artificial, como ChatGPT, através de
                      parcerias com empresas como a OpenAI.
                    </span>
                  </p>
                  <a
                    className='group inline-flex h-14 px-7 items-center justify-center text-base font-medium text-teal-900 hover:text-white bg-white hover:bg-teal-600 border border-gray-200 hover:border-teal-600 transition duration-200 rounded-full'
                    href='#'
                  >
                    <span className='mr-2'>COMECE DE GRAÇA</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z'
                        fill='currentColor'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <section class="pt-20 pb-4">
              <div class="container mx-auto">

                <div class="flex flex-wrap -m-4">
                  <div class="w-full md:w-1/2 lg:w-1/3 p-4">
                    <div class="relative mb-8 overflow-hidden rounded-lg">
                      <img class="w-full transform hover:scale-125 transition duration-1000" src="https://images.unsplash.com/photo-1692606742905-c4bd330534c4?crop=entropy&amp;cs=srgb&amp;fm=jpg&amp;ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxN3x8QXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfDJ8fHwxNzAwODI2ODc2fDA&amp;ixlib=rb-4.0.3&amp;q=85&amp;w=1920" alt=""/>
                      <div class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-900 to-transparent p-8">
                        <span class="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">Plataforma</span>
                        <a class="group inline-block max-w-sm" href="https://chat.gptbr.com">
                          <h3 class="mb-4 text-3xl text-white tracking-3xl hover:underline">Use o ChatGPT gratuitamente</h3>
                        </a>
                        <a class="group inline-flex items-center" href="https://chat.gptbr.com">
                          <span class="mr-3.5 text-white font-medium">Cadastre-se agora</span>
                          <svg class="transform group-hover:rotate-90 transition duration-300" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 0.75L1 11.25" stroke="white" stroke-width="1.43182" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M11.5 10.3781V0.75H1.87187" stroke="white" stroke-width="1.43182" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 lg:w-1/3 p-4">
                    <div class="relative mb-8 overflow-hidden rounded-lg">
                      <img class="w-full transform hover:scale-125 transition duration-1000" src="https://images.unsplash.com/photo-1692606742859-c519be004f95?crop=entropy&amp;cs=srgb&amp;fm=jpg&amp;ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxNnx8QXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfDJ8fHwxNzAwODI2ODY4fDA&amp;ixlib=rb-4.0.3&amp;q=85&amp;w=1920" alt=""/>
                      <div class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-900 to-transparent p-8">
                        <span class="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">Blog</span>
                        <a class="group inline-block max-w-sm" href="https://blog.gptbr.com">
                          <h3 class="mb-4 text-3xl text-white tracking-3xl hover:underline">Acesse nosso Blog sobre IA</h3>
                        </a>
                        <a class="group inline-flex items-center" href="https://blog.gptbr.com">
                          <span class="mr-3.5 text-white font-medium">Acessar o blog</span>
                          <svg class="transform group-hover:rotate-90 transition duration-300" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 0.75L1 11.25" stroke="white" stroke-width="1.43182" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M11.5 10.3781V0.75H1.87187" stroke="white" stroke-width="1.43182" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 lg:w-1/3 p-4">
                    <div class="relative mb-8 overflow-hidden rounded-lg">
                      <img class="w-full transform hover:scale-125 transition duration-1000" src="https://images.unsplash.com/photo-1692606742912-b4f9c7102869?crop=entropy&amp;cs=srgb&amp;fm=jpg&amp;ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwxOXx8QXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfDJ8fHwxNzAwODI2ODc2fDA&amp;ixlib=rb-4.0.3&amp;q=85&amp;w=1920" alt=""/>
                      <div class="absolute bottom-0 left-0 w-full bg-gradient-to-t from-gray-900 to-transparent p-8">
                        <span class="inline-block mb-4 text-sm text-green-400 font-medium tracking-tighter">Áudio</span>
                        <a class="group inline-block max-w-sm" href="https://chat.gptbr.com">
                          <h3 class="mb-4 text-3xl text-white tracking-3xl hover:underline">Crie áudios com a IA Remix</h3>
                        </a>
                        <a class="group inline-flex items-center" href="https://chat.gptbr.com">
                          <span class="mr-3.5 text-white font-medium">Saiba mais</span>
                          <svg class="transform group-hover:rotate-90 transition duration-300" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.5 0.75L1 11.25" stroke="white" stroke-width="1.43182" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M11.5 10.3781V0.75H1.87187" stroke="white" stroke-width="1.43182" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

